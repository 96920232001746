.Container {
	height: 150px;
	background-color: #5f6163;

	.ContainerInner {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;

		a {
			text-decoration: none;
			color: white;
		}
	}
}
